<template>
<div v-if="$i18n.locale === 'th'" id="helpButtonContainer">
    <div id="helpButtonTooltipContainer" class="tooltip-container">
        <button id="helpButtonToggle" type="button" @click="toggleSidebar" :class="['help-button', { 'header-style': isHeader }]">?
            <!-- <img src="/img/icon/questiononly.png" alt="Help Icon" :class="['help-icon-header', { 'help-icon': !isHeader }]" /> -->
        </button>
        <div id="helpButtonTag" :class="['help-tag', { 'left': isHeader, 'right': !isHeader }]">{{ $t('help') }}</div>
    </div>
    <div id="helpButtonOverlay" v-if="isSidebarOpen" class="overlay" @click="toggleSidebar"></div>
    <div id="helpButtonSidebar"ref="sidebar" :class="['sidebar', { open: isSidebarOpen }]" @click="toggleSidebar">
        <div id="helpButtonCloseSidebar" class="close-sidebar-btn" >✕</div>
        <div id="helpButtonContent" class="content">
            <h3 id="helpButtonHeaderTitle" class="text-success text-left">{{ headerTitle }}{{ currentPathTitle }}</h3>
            <h5 id="helpButtonVideoFor" class="text-dark text-left">{{ $t('videofor') }}</h5>
            <ul id="helpButtonVideoList" class="video-list" @click.stop>
                <li :id="'helpButtonVideoItem'-(index)" v-for="(video, index) in videoLinks" :key="index" class="video-item">
                    <div :id="`helpButtonVideoWrapper`-(index)" v-if="video.showVideo || video.url" :key="videoKey" class="video-wrapper">
                        <div :id="`helpButtonVideoPlayer`-(index)" class="w-100" style="border-radius: 4px; overflow:hidden">
                            <LazyYoutube :src="video.url" aspect-ratio="16:9" class="centered-video" />
                        </div>
                        <div :id="`helpButtonVideoInfoContainer`-(index)" class="video-info-container">
                            <p :id="`helpButtonVideoTitle`-(index)" class="video-title">{{ $t(video.title) }}</p>
                            <button :id="`helpButtonOpenLink`-(index)" @click="openExternalLink(video.link)" class="open-link-btn">{{ $t('seemore') }}</button>
                        </div>
                    </div>
                    <div v-else-if="!video.showVideo || !video.url" :id="`helpButtonThumbnailItem`"  @click="handleThumbnailClick(video)" class="thumbnail-item w-100">
                        <div :id="`helpButtonThumbnailWrapper`-(index)" class="thumbnail-wrapper">
                            <img :id="`helpButtonThumbnailImage`-(index)" :src="video.thumbnail" alt="Thumbnail" class="thumbnail" />
                        </div>
                        <div :id="`helpButtonThumbnailInfo`-(index)" class="thumbnail-info d-flex justify-content-between w-100">
                            <p :id="`helpButtonThumbnailTitle`-(index)" class="video-title">{{ $t(video.title) }} </p>
                            <span :id="`helpButtonThumbnailIcon`-(index)"><i class="fi fi-rr-arrow-up-right-from-square"></i></span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <p id="helpButtonMoreVideos" @click="goToSupport" style="cursor: pointer;" class="hover-effect text-right mr-3">{{ $t('moreVideo') }} <i class="fi fi-rr-arrow-up-right-from-square"></i></p>
    </div>
</div>
</template>

<script>
import videoData from '../containers/helpbutton.json'
import {
    LazyYoutube
} from "vue-lazytube"
export default {
    components: {
        LazyYoutube,
    },
    data() {
        return {
            videoLinks: [],
            currentPathTitle: '',
            videoKey: 0,
            headerTitle: '',
            isSidebarOpen: false,
        };
    },
    props: {
        isHeader: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.loadVideoData();
        const openHelp = localStorage.getItem("openHelp");
        if(!this.isHeader){
          if (openHelp === null) {
            this.isSidebarOpen = true;
            localStorage.setItem("openHelp", "true");
            } else {
                this.isSidebarOpen = openHelp === "true";
            }  
        }else{
            this.isSidebarOpen = false
        }
        
    },
    watch: {
        '$route.path': function (newPath, oldPath) {
            this.loadVideoData();
        },
        '$i18n.locale': function (newLocale, oldLocale) {
            this.loadVideoData();
        },
    },
    methods: {
        toggleSidebar() {
            this.isSidebarOpen = !this.isSidebarOpen
            localStorage.setItem("openHelp", this.isSidebarOpen ? "true" : "false")
        },
        loadVideoData() {
            try {
                const data = videoData
                const path = this.$route.path

                if (path.includes('/inventory/transferOut')) {
                    this.currentPathTitle = this.$t('transferOutMenu');
                    this.videoLinks = data.inventory.transferOut;
                } else if (path.includes('/inventory/transferIn')) {
                    this.currentPathTitle = this.$t('transferInMenu');
                    this.videoLinks = data.inventory.transferIn;
                } else if (path.includes('/inventory/transfer/transferReport')) {
                    this.currentPathTitle = this.$t('transferReportTXT');
                    this.videoLinks = data.inventory.transferIn;
                } else if (path.includes('/inventory/adjust-stock')) {
                    this.currentPathTitle = this.$t('warehouseTxt');
                    this.videoLinks = data.inventory.inventoryStock;
                } else if (path.includes('/inventory/stock-in')) {
                    this.currentPathTitle = this.$t('warehouseTxt');
                    this.videoLinks = data.inventory.inventoryStock;
                } else if (path.includes('/inventory/stock-out')) {
                    this.currentPathTitle = this.$t('warehouseTxt');
                    this.videoLinks = data.inventory.inventoryStock;
                } else if (path.includes('/inventory/check-stock')) {
                    this.currentPathTitle = this.$t('warehouseTxt');
                    this.videoLinks = data.inventory.inventoryStock;
                } else if (path.includes('/product/importProduct')) {
                    this.currentPathTitle = this.$t('management.productManagement');
                    this.videoLinks = data.product.import;
                } else if (path.includes('/product/addProduct')) {
                    this.currentPathTitle = this.$t('management.productManagement');
                    this.videoLinks = data.product.addproduct;
                } else if (this.$route.name == 'ProductDetail') {
                    this.currentPathTitle = this.$t('management.productManagement');
                    this.videoLinks = data.product.addproduct;
                } else if (path.includes('/product/option')) {
                    this.currentPathTitle = this.$t('management.productManagement');
                    this.videoLinks = data.product.option;
                } else if (path.includes('/product')) {
                    this.currentPathTitle = this.$t('management.productManagement');
                    this.videoLinks = data.product.product;
                } else if (path.includes('/report/daily')) {
                    this.currentPathTitle = this.$t('report.txtdaily');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/transaction')) {
                    this.currentPathTitle = this.$t('exportInvoice');
                    this.videoLinks = data.report.transaction;
                } else if (path.includes('/report/salesChannels')) {
                    this.currentPathTitle = this.$t('report.txtSalesChannelsReport');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/optional')) {
                    this.currentPathTitle = this.$t('report.txtOptionalsales');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/sku')) {
                    this.currentPathTitle = this.$t('report.txtsku');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/sell')) {
                    this.currentPathTitle = this.$t('report.txtsellbyProduct');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/plu')) {
                    this.currentPathTitle = this.$t('report.plu');
                    this.videoLinks = data.report.daily;
                } else if (path.includes('/report/timesheet')) {
                    this.currentPathTitle = this.$t('timesheetReport');
                    this.videoLinks = data.setting.staff;
                } else if (path.includes('/report/cashier')) {
                    this.currentPathTitle = this.$t('report.txtbyCashier');
                    this.videoLinks = data.setting.staff;
                } else if (path.includes('/report/payment')) {
                    this.currentPathTitle = this.$t('report.txtpaymentreport');
                    this.videoLinks = data.report.payment;
                } else if (path.includes('/report/closesell')) {
                    this.currentPathTitle = this.$t('report.txtCloseSale');
                    this.videoLinks = data.report.closesell;
                } else if (path.includes('/report/tax')) {
                    this.currentPathTitle = this.$t('report.txtSalesTax');
                    this.videoLinks = data.report.tax;
                } else if (path.includes('/report/taxBillSales')) {
                    this.currentPathTitle = this.$t('taxBillSalesTXT');
                    this.videoLinks = data.report.tax;
                } else if (path.includes('/inventory/transfer/transferReport')) {
                    this.currentPathTitle = this.$t('transferReportTXT');
                    this.videoLinks = data.report.transfer;
                } else if (path.includes('/setting/shop')) {
                    this.currentPathTitle = this.$t('settingHelp.shop.title2');
                    this.videoLinks = data.setting.shop;
                } else if (path.includes('/setting/payment')) {
                    this.currentPathTitle = this.$t('payment');
                    this.videoLinks = data.report.payment;
                } else if (path.includes('/setting/self-service/qr-order')) {
                    this.currentPathTitle = this.$t('setMenu');
                    this.videoLinks = data.setting.qrOrder;
                } else if (path.includes('/setting/self-service')) {
                    this.currentPathTitle = this.$t('setMenu');
                    this.videoLinks = data.setting.qrOrder;
                } else if (path.includes('/setting/cashier')) {
                    this.currentPathTitle = this.$t('setMenu');
                    this.videoLinks = data.setting.staff;
                } else if (path.includes('/setting/permission')) {
                    this.currentPathTitle = this.$t('permission');
                    this.videoLinks = data.setting.staff;
                } else if (path.includes('/user')) {
                    this.currentPathTitle = this.$t('setMenu');
                    this.videoLinks = data.user;
                } else {
                    this.currentPathTitle = 'ข้อมูลทั่วไป';
                    this.videoLinks = [];
                }
                this.videoLinks.forEach(video => {
                    video.showVideo = false;
                });
            } catch (error) {
                console.error('Error loading video data:', error);
            }
        },
        goToSupport() {
            window.open('https://support.silompos.com', '_blank');
        },
        handleThumbnailClick(video) {
            if (video.url) {
                video.showVideo = true;
                this.videoKey++;
            } else {
                this.openExternalLink(video.link);
            }
        },
        openExternalLink(link) {
            if (link) {
                window.open(link, '_blank');
            }
        },
    },
};
</script>

<style scoped>
.help-icon-header {
  width: 20px;
  height: 20px;
}
.help-icon {
  width: 12px;
  height: 12px;
}
.hover-effect:hover {
    color: var(--success);
    text-decoration: underline;
    cursor: pointer;
}

.tooltip-container {
    position: relative;
    display: inline-flex;
    align-items: center;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1089;
}

.help-tag {
    visibility: hidden;
    background-color: #ffffff;
    color: var(--dark);
    text-align: center;
    border-radius: 8px;
    padding: 8px 12px;
    border: 1px solid #dcdcdc;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    font-size: 14px;
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; 
}

.tooltip-container:hover .help-tag {
    visibility: visible;
    opacity: 1;
}

.help-tag.left {
    right: calc(100% + 5px);
    margin-right: 10px;
}

.help-tag.left::after,
.help-tag.left::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.help-tag.left::after {
    border-left: 8px solid #ffffff;
    right: -8px;
    z-index: 2;
}

.help-tag.left::before {
    border-left: 10px solid #dcdcdc;
    right: -10px;
    z-index: 1;
}

.help-tag.right {
    left: calc(100% + 5px);
    margin-left: 10px;
}

.help-tag.right::after,
.help-tag.right::before {
    content: "";
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.help-tag.right::after {
    border-right: 8px solid #ffffff;
    left: -8px;
    z-index: 2;
}

.help-tag.right::before {
    border-right: 10px solid #dcdcdc;
    left: -10px;
    z-index: 1;
}

.help-button {
    background-color: #29b289;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    width: 32px;
    height: 32px;
    font-size: 24px;
    padding-top: 10px;
}

.help-button:hover {
    background-color: #319f80;
}

.help-button.active {
    transform: translateX(-100%);
}

.header-style {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2px;
    font-size: 24px;
}

.help-button:not(.header-style) {
    width: 18px;
    height: 18px;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    padding: 0px;
    font-size: 16px;
    padding-top: 2px;
}

.sidebar {
    position: fixed;
    right: -100%;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: #f7f7f7;
    box-shadow: -2px 0 5px rgba(204, 204, 204, 0.5);
    padding: 20px;
    transition: right 0.6s ease-in-out;
    z-index: 1090;
    overflow-y: auto;
}

.sidebar.open {
    right: 0;
}

.close-sidebar-btn {
    color: var(--dark);
    border: none;
    cursor: pointer;
    font-size: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1001;
    background-color: #f7f7f7;
    outline: none;
}

.video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 2%;
}

.centered-video {
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    display: block;
}

.video-info-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 15px 0;
}

.open-link-btn {
    color: #29b289;
    text-decoration: underline;
    border: none;
    margin-left: auto;
    background-color: white;
    outline: none;
    margin: 0;
    cursor: pointer;
    white-space: nowrap;
}

.content {
    padding: 10px;
    font-size: 16px;
    color: #333;
}

.video-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.video-item {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid transparent;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    text-align: left;
}

.video-item:hover {
    border-color: #29b289;
}

.video-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
}

.thumbnail-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: white;
    border: none;
    /* ลบเส้นขอบ */
    box-shadow: none;
    /* ลบเงา */
    transition: background-color 0.3s ease;
}

.thumbnail-item:hover {
    border-color: #29b289;
}

.thumbnail-wrapper {
    position: relative;
    margin-right: 20px;
    width: 192px;
}

.thumbnail {
    width: 100%;
    height: auto;
    border-radius: 4px;
}

.thumbnail-info {
    flex: 1;
}

.thumbnail-info .video-title {
    text-align: left;
    font-weight: normal
}

.play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: var(--success);
    pointer-events: none;
    opacity: 50%
}

.video-info {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.video-title {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    color: var(--dark);
}

@media (min-width: 769px) {
    .sidebar {
        width: 35vw;
    }

    .help-button.active {
        transform: translateX(-35vw);
    }
}

.help-button {
  background-color: #29b289;
  border: none;
  border-radius: 50%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  width: 32px;
  height: 32px;
  line-height: 1;
  padding: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
</style>
